import { Injectable } from '@angular/core';
import { Observable, from, map, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import OktaAuth from '@okta/okta-auth-js';
import { OKTAConfig } from './okta.config';
import { AppService } from '../../../../app.service';

export const OKTA_INITIAL_TOKEN_CHECK = 1000;
export const OKTA_CHECK_INTERVAL = 5 * 60 * 1000;

@Injectable()
export class OktaService {

  private get url(): {
    refreshToken: string,
    accessToken: string,
  } {
    return this.appService.getApiUrls().okta;
  }

  private oktaAuth;
  private config;

  constructor(protected appService: AppService,
    protected httpClient: HttpClient) {
  }

  public getConfig() {
    return this.config;
  }

  public initOktaAuth(okta: any) {
    this.config =  Object.assign({}, OKTAConfig, okta);
    this.oktaAuth = new OktaAuth({
      pkce:  false,
      clientId: this.config.clientId,
      issuer: this.config.issuer,
      redirectUri: this.config.redirectUri,
    });
  }

  async signIn() {
		await this.oktaAuth.token.getWithRedirect({ scopes: this.oktaAuth.options.scopes, responseType: ['token', 'id_token'] });
	}

  public goToLoginPage() {
    const { scope } = this.getConfig();

    this.oktaAuth.token.getWithRedirect({
      scopes: scope.split(' '),
      responseType: ['id_token', 'token', 'code'],
    });
  }

  public async logout() {
    await this.oktaAuth.tokenManager.clear();
    return await this.oktaAuth.signOut();
  }

  public async handleAuthentication() {
    const auth = await this.oktaAuth.token.parseFromUrl();
    if(auth?.tokens){
      this.oktaAuth.tokenManager.add('idToken', auth.tokens.idToken);
      this.oktaAuth.tokenManager.add('accessToken',  auth.tokens.accessToken);
    }
  }

  public isAuthenticated(): Observable<any> {
    const token =  this.getAccessToken();
    return from(token);
  }

  public async isImpersonated() {
    const token = await this.getAccessToken();
    const { impersonation } = OktaService.parseToken(token.accessToken);

    return !!impersonation;
  }

  public async isTokenExpired() {
    const token = await this.getAccessToken();
    return token.expiresAt * 1000 < Date.now();
  }

  public async getAccessToken() {
    const data = await this.oktaAuth?.tokenManager.get('accessToken');
    return data;
  }

  public getOktaRefreshToken(params): Observable<any> {
    return this.httpClient.get(this.url.refreshToken, {
      responseType: 'text',
      params: this.getHttpParams(params)
    }).pipe(map((token) => JSON.parse(token)));
  }

  public refreshOktaAccessToken(): Observable<any> {
    const config = this.getConfig();
    const refreshToken = localStorage.getItem('okta-refresh-token');
    if(refreshToken == null){
      return of(null);
    }
    const params = {
      refreshToken: refreshToken,
      scopes: encodeURIComponent(config.scope),
    };

    return this.httpClient.get(this.url.accessToken, {
      responseType: 'text',
      params: this.getHttpParams(params)
    }).pipe(map((newToken) => {
      const tokens = JSON.parse(localStorage.getItem('okta-token-storage'));

      tokens.accessToken.accessToken = JSON.parse(newToken);
      this.oktaAuth.tokenManager.add('accessToken', tokens.accessToken);

      return OktaService.parseToken(newToken);
    }));
  }

  public async isTokenAboutToExpire() {
    const token = await this.oktaAuth.tokenManager.get('accessToken');

    const { exp } = OktaService.parseToken(token.accessToken);

    return (exp * 1000) - Date.now() <= OKTA_CHECK_INTERVAL;
  }

  public static parseToken(token) {
    const [ base64Url] = token.split('.');
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }

  private getHttpParams(params) {
    return Object.getOwnPropertyNames(params)
      .reduce((httpParams, prop) => httpParams.set(prop, params[prop]), new HttpParams());
  }
}
